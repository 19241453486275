//
// Layout
// --------------------------------------------------

.o-pagelayout__inner {
  position: relative;

  // holy grail
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.o-main {
  // holy grail
  flex-grow: 1;
  width: 100%;

  position: relative;
  z-index: index($z-index, main);

  padding-top: $topbar-height-lg; // fallback
  padding-top: calc(#{'var(--topbar-height)'} * 1px);
  padding-bottom: rem($section-spacing);

  @include mappy-bp(sm) {
    padding-bottom: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-bottom: rem($section-spacing-lg);
  }
}

.o-main__hero,
.o-main__article {
  position: relative;
  z-index: 10; // > bg visual
}

// Extra spacing for family site
body.homeFamily .o-main__article {
  padding-top: rem(24px);

  @include mappy-bp(sm) {
    padding-top: rem(48px);
  }

  @include mappy-bp(lg) {
    padding-top: rem(56px);
  }
}

// not that great but works for now
.o-main__hero .c-hero-animation {
  margin-bottom: rem(40px);

  @include mappy-bp(sm) {
    margin-bottom: rem(56px);
  }
}


// BG Visual
.o-main__bg-visual {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  top: calc(#{'var(--topbar-height)'} / 3 * 1px);

  @include mappy-bp(480px) {
    top: 0;
  }

  @include mappy-bp(md) {
    top: calc(#{'var(--topbar-height)'} / 2 * 1px);
  }

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.o-main__bg-visual-img {
  display: block;
  object-fit: contain;
  object-position: top center; // magic, but it makes sense since the box is very tall on mobile
  width: 100%;
  height: auto;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}


// Sections
.o-main__section {
  overflow-x: hidden; // prevent overflow scroll from negative grid margins
  position: relative;
  padding-bottom: rem($section-spacing);

  @include mappy-bp(sm) {
    padding-bottom: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-bottom: rem($section-spacing-lg);
  }
}

// Color BGs
.o-main__section--bg-color-cyan {
  background-color: $color-cyan;
  color: #fff;
}

.o-main__section--bg-color-yellow {
  background-color: $color-yellow;
  color: #fff;
}

.o-main__section--bg-color-orange {
  background-color: $color-orange;
  color: #fff;
}

.o-main__section--bg-color-green {
  background-color: $color-green;
  color: #fff;
}

.o-main__section--bg-color-dark-blue {
  background-color: $color-dark-blue;
  color: #fff;
}

// Brush BG
.o-main__section--bg-brush {
  background-image: url('../../img/brush-pattern-bg.png');
  background-repeat: repeat-x;
  background-position: top center;
  background-size: contain;
}

.o-main__section--bg-brush-md {
  @include mappy-bp(md) {
    background-image: url('../../img/brush-pattern-bg.png');
    background-repeat: repeat-x;
    background-position: top center;
  }
}

// Sparkles BG
.o-main__section--bg-sparkles {
  background-image: url('../../img/sparkles-bg.png');
  background-repeat: repeat-x;
  background-position: bottom center;
}

.o-main__section--bg-sparkles-2 {
  background-image: url('../../img/sparkles-bg.png');
  background-repeat: repeat-x;
  background-position: top right;
}

// Spacing
.o-main__section--with-top-spacing,
[class*="o-main__section--push-next"] + .o-main__section:not(.o-main__section--no-top-spacing) { // keep an eye on this. could be moved onto twig logic.
  padding-top: rem($section-spacing);

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg);
  }
}

.o-main__section--no-bottom-spacing {
  padding-bottom: 0;
}

.o-main__section--allow-overflow {
  overflow: visible;
}
