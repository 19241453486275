//
// Gallery
// --------------------------------------------------

@import "~@glidejs/glide/src/assets/sass/glide.core";

.c-gallery {
  &.glide--swipeable {
    cursor: grab;
  }

  &.glide--dragging {
    cursor: grabbing;
  }

  .glide__arrow {
    @include button-reset;
    cursor: pointer;

    transition: all $transition-time-default;

    &:not(:first-of-type) {
      margin-left: rem(48px);
    }

    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }
  }

  .glide__slide {
    height: auto;
  }
}

.c-gallery__track-wrapper {
  // break out of parent container
  @supports (--css: variables) {
    // < xs
    margin-right: calc(-100vw / 2 + (100% + var(--scroll-bar) * 1px) / 2);

    @each $breakpoint in (xs, sm, md, lg, xl) {
      @include mappy-bp($breakpoint) {
        margin-right: calc(-100vw / 2 + (#{map-get($container-max-widths, $breakpoint)} + var(--scroll-bar) * 1px) / 2);
      }
    }
  }
}

.c-gallery__arrows {
  margin-top: rem(24px);
  display: flex;
  justify-content: center;

  @include mappy-bp(sm) {
    justify-content: flex-end;
  }

  @include mappy-bp(lg) {
    margin-top: rem(56px);
  }
}

.c-gallery__arrows-inner {
  display: flex;

  @include mappy-bp(map-get($container-max-widths, xl) + ($link-button-icon-size-sm + 48px + 48px) * 2) {
    transform: translateX(100%) translateX(rem(-$link-button-icon-size-sm));
  }
}

.c-gallery__slide {
  display: block;
  height: 100%;
  background-color: #fff;

  .glide--dragging & {
    pointer-events: none;
  }
}

.c-gallery__slide-inner {
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
}

.c-gallery__img-wrapper {
  height: 0;
  padding-bottom: 2 / 3 * 100%;
  overflow: hidden;
  position: relative;

  background-color: $color-gray-f;
}

.c-gallery__img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: opacity $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

.c-gallery__caption {
  flex-grow: 1;
  display: flex;
  align-items: center;

  color: $color-text;
  @include typi('caption');
  text-align: center;
  padding: rem(16px);

  @include mappy-bp(sm) {
    padding: rem(24px);
  }

  @include mappy-bp(lg) {
    padding: rem(40px);
  }

  p {
    width: 100%;
    font-size: inherit;
    line-height: inherit;
  }
}

// Wrapper component
.c-gallery-section__top {
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(72px);

    display: flex;
    justify-content: space-between;
  }
}

.c-gallery-section__text {
  flex-grow: 1;
}

.c-gallery-section__detail-link {
  flex-shrink: 0;
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(32px);
  }

  @include mappy-bp(md) {
    margin-top: 0;
    margin-left: rem(48px);
  }
}
