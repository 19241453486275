//
// Panels
// --------------------------------------------------

$panel-padding-x: 16px;
$panel-padding-x-sm: 32px;

.c-panel {
  position: relative;
  width: 100%;

  & + & {
    margin-top: rem(16px);

    @include mappy-bp(sm) {
      margin-top: rem(24px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(48px);
    }
  }
}

.c-panel__header-wrapper {
  position: relative;
  display: flex;
}

.c-panel__header {
  flex-grow: 1;

  @include button-reset;
  cursor: pointer;
  text-align: left;
  color: $color-dark-blue;
  background-color: $color-light-blue;

  display: flex;
  align-items: baseline;
  width: 100%;
  padding: rem(16px) rem($panel-padding-x);
  transition: all $transition-time-default;

  @include mappy-bp(sm) {
    padding: rem(24px) rem($panel-padding-x-sm);
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-dark-blue;
      color: #fff;
    }
  }

  &:focus {
    background-color: $color-dark-blue;
    color: #fff;
  }
}

.c-panel__header-content {
  width: 100%;

  @include mappy-bp(sm) {
    display: flex;
    align-items: baseline;
  }
}

.c-panel__meta-headline {
  display: block;
  margin-bottom: rem(8px);
  color: $color-text;
  transition: all $transition-time-default;
  @include typi('h6');
  flex-shrink: 0;

  @include mappy-bp(sm) {
    margin-bottom: 0;
    margin-right: rem(24px);
    width: 13%;
  }

  @include mappy-bp(lg) {
    margin-right: rem(40px);
  }

  @media (hover: hover) {
    .c-panel__header:hover & {
      color: #fff;
    }
  }

  .c-panel__header:focus & {
    color: #fff;
  }
}

.c-panel__headline {
  display: block;
  font-weight: 700;
  flex-grow: 1;
}

.c-panel__toggle-icon {
  flex-shrink: 0;
  @include typi('h4');
  width: 1em;
  height: 1em;
  margin-left: rem(16px);
  transform: translateY(.15em);

  @include mappy-bp(sm) {
    margin-left: rem(24px);
  }

  @include mappy-bp(lg) {
    margin-left: rem(48px);
  }

  svg {
    height: 100%;
    width: 100%;

    &:last-of-type {
      display: none;
    }
  }

  .c-panel.is-expanded & {
    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: block;
    }
  }
}

.c-panel__panel {
  &.houdini {
    max-height: 75vh; // overwritten by JS
    transition: max-height ease-in-out $transition-time-default;
    overflow: hidden;

    .c-panel.is-collapsed & {
      max-height: 0 !important;
    }
  }
}

.c-panel__content {
  padding: rem(16px) rem($panel-padding-x);

  @include mappy-bp(sm) {
    padding: rem(24px) rem($panel-padding-x-sm);
  }

  @include mappy-bp(lg) {
    padding: rem(48px) rem($panel-padding-x-sm);
  }
}


// Panel lists
.c-panel-list {
  position: relative;
  max-width: rem(map-get($container-max-widths, lg));
}
