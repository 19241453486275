//
// Blockquote
// --------------------------------------------------

.c-blockquote {
  color: $color-dark-blue;

  @include mappy-bp(sm) {
    display: flex;
  }
}

.c-blockquote--inverted {
  color: #fff;
}

.c-blockquote__icon {
  width: rem(40px);
  height: rem(40px);
  margin-bottom: rem(8px);

  display: flex;
  align-items: center;
  justify-content: center;

  @include mappy-bp(sm) {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: rem(32px);
    width: rem(64px);
    height: rem(64px);
  }

  @include mappy-bp(lg) {
    width: rem(80px);
    height: rem(80px);
  }

  svg {
    width: 80%;
    height: 80%;
  }
}

.c-blockquote__quote {
  @include typi('blockquote-quote');
  font-style: italic;
}

.c-blockquote__cite {
  display: block;
  margin-top: rem(24px);
  text-align: right;
  @include typi('blockquote-cite');

  @include mappy-bp(sm) {
    margin-top: rem(40px);
  }

  p {
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    text-transform: uppercase;
  }
}
