//
// Richtext lists
// --------------------------------------------------

// get $typi.paragraph.null font size (without 'px')
$list-base-font-size: _ty-strip-unit(_ty-get-font-size(map-deep-get($typi, paragraph, null)));
$list-bullet-space: 22px;
$list-bullet-space-sm: 40px;
$list-bullet-space-lg: 58px;
$list-bullet-space-sub-ol: 28px;
$list-bullet-space-sub-ol-sm: 49px;
$list-bullet-space-sub-ol-lg: 71px;

.c-richtext {
  // general list
  ul,
  ol {
    @include typi('paragraph');
    padding-left: rem($list-bullet-space);

    @include mappy-bp(sm) {
      padding-left: rem($list-bullet-space-sm);
    }

    @include mappy-bp(lg) {
      padding-left: rem($list-bullet-space-lg);
    }

    li {
      clear: left;

      // bullet point / counter / badge
      &::before {
        content: '';
        float: left;
        margin-left: rem(-$list-bullet-space);

        @include mappy-bp(sm) {
          margin-left: rem(-$list-bullet-space-sm);
        }

        @include mappy-bp(lg) {
          margin-left: rem(-$list-bullet-space-lg);
        }
      }

      p:last-of-type,
      p.p-last {
        padding: 0;
      }
    }
  }

  // general sub list
  li ul,
  li ol {
    margin-top: rem(24px);
  }

  .c-table li ul,
  .c-table li ol {
    margin-top: rem(8px);
  }


  // bullet point
  ul:not(.c-checked-list) li::before {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M1.813 15.399C-.907 19.036 0 20.854.906 22.673c.907 1.818 5.44 4.546 9.065 2.727 3.626-1.818 5.44-7.274 2.72-10.001-2.72-2.728-8.159-3.637-10.878 0z' id='a'/%3E%3C/defs%3E%3Cuse fill='%2341789c' xlink:href='%23a' transform='translate(0 -13)' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    width: (8.5 / $list-base-font-size) * 1em;
    height: (8.5 / $list-base-font-size) * 1em;
    margin-top: -.05em; // magic number, based on the bullet point dimensions and font-size
  }

  // sub list bullet point is identical to main bullet point here
  //ul:not(.c-checked-list) li ul li::before {
  //}


  // counter
  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;

        font-weight: 700;
        color: $color-dark-blue;
      }
    }
  }

  &.c-richtext--inverted ol li::before {
    color: inherit;
  }

  // sub ol
  ol li ol {
    padding-left: rem($list-bullet-space-sub-ol);

    @include mappy-bp(sm) {
      padding-left: rem($list-bullet-space-sub-ol-sm);
    }

    @include mappy-bp(lg) {
      padding-left: rem($list-bullet-space-sub-ol-lg);
    }

    // counter
    li::before {
      content: counters(item, ".");

      margin-left: rem(-$list-bullet-space-sub-ol);

      @include mappy-bp(sm) {
        margin-left: rem(-$list-bullet-space-sub-ol-sm);
      }

      @include mappy-bp(lg) {
        margin-left: rem(-$list-bullet-space-sub-ol-lg);
      }
    }
  }

  // checked list
  ul.c-checked-list {
    li {
      &::before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M4.228 13.154c-1.98.684-2.64 1.71-1.98 3.077 1.32 3.419 2.31 5.47 2.97 6.154.66.683 1.65 2.05 2.97 4.102 1.32.684 2.31.684 2.97 0 .99-1.025 4.95-6.154 8.911-10.256C24.03 12.128 27 11.103 27 10.077 27 9.05 25.02 7 24.03 7c-.99 0-9.901 8.718-10.891 10.256-.99 1.539-2.97 4.103-3.96 2.052-.99-2.052-1.981-7.18-4.951-6.154z' id='a'/%3E%3C/defs%3E%3Cuse fill='%2341789c' xlink:href='%23a' transform='translate(-2 -7)' fill-rule='evenodd'/%3E%3C/svg%3E%0A");

        width: (14 / $list-base-font-size) * 1em;
        height: (14 / $list-base-font-size) * 1em;
      }
    }
  }
}
