//
// Hero
// --------------------------------------------------

.c-hero {
  position: relative;
  z-index: 10; // > hero animation

  .c-article-actions + & {
    margin-top: rem(32px);

    @include mappy-bp(sm) {
      margin-top: rem(72px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(96px);
    }
  }
}

.c-hero__inner {
  @include page-offset;
  max-width: rem(1128px);
}
