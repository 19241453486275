//
// Buttons
// --------------------------------------------------

$button-border-width: 3px;
$button-diagonal-offset: $diagonal-offset;

.c-button {
  @include button-reset;
  cursor: pointer;
  @include typi('button');
  font-weight: 700;
  text-transform: uppercase;
  transition: all $transition-time-default;

  position: relative; // for background
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-width: rem(160px);

  background-color: #fff;
  background-image: url('../../img/button-bg-orange.png');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  color: $color-primary;
  border: rem($button-border-width) solid currentColor;

  // Diagonal bottom border
  @supports (clip-path: polygon(0 0)) {
    border-bottom-color: transparent;
    clip-path: polygon(100% 0, 100% $button-diagonal-offset, 0 100%, 0 0);

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      left: #{rem(-$button-border-width)};
      right: #{rem(-$button-border-width)};
      bottom: #{rem(-$button-border-width)};
      top: #{rem(-$button-border-width)};
      clip-path: polygon(100% calc(#{$button-diagonal-offset} - #{rem($button-border-width)}), 100% 100%, 0% 100%, 0 calc(100% - #{rem($button-border-width)}));
      background-color: currentColor;
    }

    .c-button__text {
      transform: translateY(rem(-$button-border-width / 2));
    }
  }

  // Hover background
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    background-color: currentColor;
    transition: all $transition-time-default;
    opacity: 0;
  }

  .c-teaser-site:hover &,
  .c-teaser-site:focus &,
  &:hover,
  &:focus {
    .c-button__text {
      color: #fff;
    }

    &::before {
      opacity: 1;
    }
  }
}

// Cyan
.c-button--cyan {
  color: $color-cyan;
  background-image: url('../../img/button-bg-cyan.png');
}

// Text
.c-button__text {
  z-index: 10; // > background
  padding: rem(8px) rem(20px);
  transition: all $transition-time-default;

  @include mappy-bp(sm) {
    padding: rem(17px) rem(36px);
  }
}

// Button group
$button-group-spacing: 24px;

.c-button-group {
  overflow: hidden;

  .c-button {
    margin-top: rem($button-group-spacing);
    margin-left: rem($button-group-spacing);
    margin-right: rem($button-group-spacing);
  }
}

.c-button-group--stretch {
  .c-button {
    flex-grow: 1;
  }
}

.c-button-group__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: rem(-$button-group-spacing);
  margin-left: rem(-$button-group-spacing);
  margin-right: rem(-$button-group-spacing);

  .c-button-group--centered & {
    justify-content: center;
    align-items: center;
  }

  .c-button-group--column-xs & {
    @include mappy-bp(max sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
