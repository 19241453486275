//
// Overlay
// --------------------------------------------------

$overlay-box-shadow-offset: 5px;
$overlay-box-shadow-blur: 10px;

.c-overlay {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: index($z-index, overlay);

  overflow: hidden;

  background-color: rgba($color-gray-5, .3);

  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility 0s .3s;
  // Out: Sharp
  transition-timing-function: cubic-bezier(.4, 0, .6, 1);

  // bg element
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin-left: auto;
    background-color: #fff;
    box-shadow: -$overlay-box-shadow-offset 0 $overlay-box-shadow-blur rgba($color-gray-5, .3);
    transform: translateX(100%) translateX($overlay-box-shadow-offset + $overlay-box-shadow-blur);
    opacity: .75;
    transition: all .3s;
    // Out: Sharp
    transition-timing-function: cubic-bezier(.4, 0, .6, 1);

    @include mappy-bp(sm) {
      width: 50%;
    }
  }

  &.is-open,
  &.is-open::after {
    // In: Deceleration
    transition: all .3s;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  > .o-container {
    height: 100%;
    position: relative;
    z-index: 20; // > bg
  }
}

.c-overlay__inner {
  height: 100%;
  width: 100%;
  margin-left: auto;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  color: $color-text;

  transform: translateX(100%);
  opacity: 0;
  transition: all .3s;
  // Out: Sharp
  transition-timing-function: cubic-bezier(.4, 0, .6, 1);

  .c-overlay.is-open & {
    // In: Deceleration
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include mappy-bp(sm) {
    width: 50%;
  }
}

.c-overlay__scroll-wrapper {
  padding-bottom: rem(64px); // generous bottom padding for iOS
}

.c-overlay__actions {
  padding-top: rem(24px);
  margin-bottom: rem(16px);
  display: flex;

  @include mappy-bp(sm) {
    padding-top: rem(48px);
    margin-bottom: rem(32px);
  }
}

.c-overlay__close {
  @include button-reset;
  cursor: pointer;
  transition: all $transition-time-default;
  display: block;

  margin-left: auto;
  min-width: 0;

  &,
  &:hover,
  &:focus {
    color: $color-primary;
  }

  svg {
    height: rem($topbar-btn-size);
    width: rem($topbar-btn-size);

    @include mappy-bp(sm) {
      height: rem($topbar-btn-size-sm);
      width: rem($topbar-btn-size-sm);
    }
  }
}

.c-overlay__close--cyan {
  &,
  &:hover,
  &:focus {
    color: $color-cyan;
  }
}

.c-overlay__content {
  @include mappy-bp(sm) {
    padding-left: rem(40px);
    padding-right: rem($topbar-btn-size-sm + 40px);
  }
}

// Make overlay component more global by using a menu modifier
.c-overlay--menu {
  @include mappy-bp(xl) {
    display: none;
  }
}
