//
// Contacts
// --------------------------------------------------

// Position
.c-contact__position {
  padding-bottom: rem(24px);

  @include mappy-bp(sm) {
    padding-bottom: rem(32px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(40px);
  }
}

// Image
.c-contact__img-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: (9 / 16) * 100%;
  height: 0;
}

.c-contact__img {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
  transition: opacity $transition-time-default;

  @include mappy-bp(lg) {
    object-position: bottom left;
  }

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-contact__content {
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(24px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(32px);
  }
}

// h5 sizing on lg
.c-contact__name {
  .c-headline--h4 {
    @include mappy-bp(lg) {
      font-size: _ty-to-rem(_ty-get-font-size(map-deep-get($typi, h5, lg)), 16px);
    }
  }
}

.c-contact__actions {
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(24px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(40px);
  }
}

.c-contact__plain-text {
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(24px);
  }
}


// Wrapper component
.c-contacts__actions {
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(24px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(40px);
  }
}

.c-contacts__top {
  padding-bottom: rem(40px);

  @include mappy-bp(sm) {
    padding-bottom: rem(72px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(96px);
  }
}

// Grid
$contacts-spacing: 32px;
$contacts-spacing-sm: 48px;
$contacts-vertical-spacing: 40px;
$contacts-vertical-spacing-sm: 72px;

.c-contacts__row {
  @include make-row-spacing($contacts-spacing, $contacts-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-row-spacing($contacts-spacing-sm, $contacts-vertical-spacing-sm);
  }
}

.c-contacts__col {
  @include make-col-spacing($contacts-spacing, $contacts-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-col-spacing($contacts-spacing-sm, $contacts-vertical-spacing-sm);
  }
}

