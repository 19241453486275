//
// Map
// --------------------------------------------------

.c-map__aspect-parent {
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  height: 0;

  @include mappy-bp(sm) {
    padding-bottom: 50%;
  }

  @include mappy-bp(md) {
    padding-bottom: 40%;
  }
}

.c-map__aspect-target {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.c-map__map {
  background-color: $color-gray-f;
  height: 100%;
  width: 100%;
}
