//
// Richtext table
// --------------------------------------------------

.c-table--responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mappy-bp(md) {
    overflow: visible;
  }

  table {
    width: 100%;
    min-width: rem(500px);
  }
}

.c-table--narrow {
  table {
    max-width: rem($content-width-narrow);
  }
}

table {
  border-collapse: collapse;
  border-style: hidden;
  caption-side: top;
  color: $color-text;

  caption {
    text-align: left;
    color: $color-dark-blue;
    margin-bottom: rem(8px);

    @include mappy-bp(lg) {
      margin-bottom: rem(16px);
    }

    p {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

// Rows
tr {
  // Head row
  thead & {
    background: rgba($color-light-blue, .5);
  }

  // Body row
  tbody & {
    &:nth-of-type(even) {
      background: rgba($color-light-blue, .5);
    }
  }

  // Foot row
  tfoot & {
    background: rgba($color-light-blue, .5);
    font-weight: 700;
  }
}

// Columns
th,
td {
  padding: rem(16px) rem(16px);

  @include mappy-bp(md) {
    padding: rem(24px);
  }

  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }

  > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

th {
  border: 0 none;
  font-weight: 700;
  text-align: left;

  tbody & {
    color: $color-dark-blue;
  }
}

// hints
.c-table__hints {
  margin-top: rem(48px);

  @include mappy-bp(sm) {
    margin-top: rem(64px);
  }

  @include mappy-bp(md) {
    margin-top: rem(96px);
  }
}
