//
// Jumbotron
// --------------------------------------------------

.c-jumbotron {
  position: relative;
  color: #fff;
  padding-top: rem($section-spacing + 16px); // quick maths taken from the layout
  padding-bottom: rem($section-spacing + 16px);

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm + 24px);
    padding-bottom: rem($section-spacing-sm + 24px);
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg + 40px);
    padding-bottom: rem($section-spacing-lg + 40px);
  }
}

.c-jumbotron__img-wrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  // bg overlay
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10; // > img
    background-color: rgba(0, 0, 0, .6);
  }
}

.c-jumbotron__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  transition: opacity $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}


.c-jumbotron__content {
  position: relative;
  z-index: 10; // > bg image
}

.c-jumbotron__icon-link {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  @include typi('intro');

  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(48px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(64px);
  }

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  svg {
    align-self: baseline;
    transform: translateY(16%); // magic :(
    flex-shrink: 0;
    margin-right: rem(16px);
    height: rem(22px);
    width: rem(22px);

    @include mappy-bp(sm) {
      height: rem(32px);
      width: rem(32px);
      margin-right: rem(24px);
    }

    @include mappy-bp(lg) {
      height: rem(40px);
      width: rem(40px);
    }
  }
}

.c-jumbotron__button-link {
  margin-top: rem(32px);

  @include mappy-bp(sm) {
    margin-top: rem(72px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(96px);
  }
}
