//
// Alpha teaser
// --------------------------------------------------

.c-teaser-alpha {
  display: flex;

  @include mappy-bp(md) {
    display: block;
  }

  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}

// Image
.c-teaser-alpha__img-block {
  flex-shrink: 0;
  width: rem(88px);
  max-width: rem(320px);

  @include mappy-bp(sm) {
    width: rem(176px);
  }

  @include mappy-bp(md) {
    width: auto;
  }
}

.c-teaser-alpha__img-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: (1 / 1) * 100%;
  height: 0;

  background-image: url('../../img/brush-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  @include mappy-bp(md) {
    background-image: none;
  }
}

.c-teaser-alpha__img {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: top left;
  transition: opacity $transition-time-default;

  @include mappy-bp(md) {
    object-position: bottom left;
  }

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-teaser-alpha__content {
  .c-teaser-alpha__img-block + & {
    margin-left: rem(8px);

    @include mappy-bp(sm) {
      margin-left: rem(48px);
    }

    @include mappy-bp(md) {
      margin-left: 0;
      margin-top: rem(48px);
    }
  }
}

.c-richtext .c-teaser-alpha__headline,
.c-teaser-alpha__icon {
  color: $color-primary;
  transition: color $transition-time-default;

  .c-teaser-alpha:hover &,
  .c-teaser-alpha:focus & {
    color: $color-dark-blue;
  }
}

.c-teaser-alpha__icon {
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(48px);
  }

  @include mappy-bp(md) {
    margin-top: rem(64px);
  }
}


// Wrapper component
.c-teasers-alpha__text {
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(72px);
  }
}

// Grid
$teasers-alpha-spacing: 32px;
$teasers-alpha-spacing-sm: 48px;
$teasers-alpha-vertical-spacing: 40px;
$teasers-alpha-vertical-spacing-sm: 72px;

.c-teasers-alpha__row {
  @include make-row-spacing($teasers-alpha-spacing, $teasers-alpha-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-row-spacing($teasers-alpha-spacing-sm, $teasers-alpha-vertical-spacing-sm);
  }
}

.c-teasers-alpha__col {
  @include make-col-spacing($teasers-alpha-spacing, $teasers-alpha-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-col-spacing($teasers-alpha-spacing-sm, $teasers-alpha-vertical-spacing-sm);
  }
}

