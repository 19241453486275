//
// Service menu
// --------------------------------------------------

.c-service-menu {
  .c-menu + & {
    margin-top: rem(48px);

    @include mappy-bp(sm) {
      margin-top: rem(64px);
    }
  }

  a {
    display: flex;
    align-items: center;

    @include typi('menu-service');
    padding: rem(16px) rem($menu-item-padding-x);
    color: $color-primary;
    transition: color $transition-time-default;

    @include mappy-bp(sm) {
      padding-top: rem(12px);
      padding-bottom: rem(12px);
    }

    &:hover,
    &:focus {
      color: $color-dark-blue;
    }

    svg {
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
  }

  li.is-active a {
    font-weight: 700;
  }
}

.c-service-menu--cyan {
  a {
    color: $color-cyan;

    &:hover,
    &:focus {
      color: $color-dark-blue;
    }
  }
}
