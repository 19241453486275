//
// Anchornav
// --------------------------------------------------

$anchornav-shadow-height: 40px;
$anchornav-shadow-height-sm: 112px;
$anchornav-shadow-height-lg: 140px;

.c-anchornav {
  position: sticky;
  z-index: 20; // > content, > section wrapper icons
  will-change: transform;
  top: 0;
  transition: top $transition-time-medium;

  @include typi('anchornav');
}

// Toggle bar
.c-anchornav__toggle-bar {
  height: rem(48px);
  background-color: $color-primary;
  color: #fff;
  font-weight: 700;

  @include mappy-bp(sm) {
    display: none;
  }

  > .o-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.c-anchornav__toggle {
  display: block;
  width: rem(24px);
  height: rem(24px);
  color: inherit;
  position: relative;

  &:hover,
  &:focus {
    color: inherit;
  }

  svg {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;

    transition: all $transition-time-default;
    transform: translate3d(0, 0, 0);

    // burger
    &:first-of-type {
      .c-anchornav.is-open & {
        transform: scale(.75);
        opacity: 0;
      }
    }

    // close
    &:last-of-type {
      transform: scale(.75);
      opacity: 0;

      .c-anchornav.is-open & {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

// Anchornav
.c-anchornav__inner {
  display: none;
  position: relative;
  transition: all $transition-time-default;

  @include mappy-bp(sm) {
    background-color: transparent;
    display: block;
  }

  .c-anchornav.is-stuck & {
    background-color: #fff;
  }

  .c-anchornav.is-open & {
    display: block;
  }

  > .o-container {
    position: relative;
    z-index: 10; // > bg
  }

  // background gradient
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    pointer-events: none;

    background-image: $gradient-primary;
    opacity: 0;
    transition: all $transition-time-default;
    height: rem($anchornav-shadow-height);

    @include mappy-bp(sm) {
      top: 0;
      height: rem($anchornav-shadow-height-sm);
    }

    @include mappy-bp(lg) {
      height: rem($anchornav-shadow-height-lg);
    }

    .c-anchornav.is-stuck & {
      opacity: 1;
    }
  }
}

.c-anchornav__nav {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(16px);
    margin-left: rem(-8px);
    margin-right: rem(-8px);

    @include mappy-bp(sm) {
      padding-top: rem(12px);
      margin-left: rem(-12px);
      margin-right: rem(-12px);
    }

    @include mappy-bp(lg) {
      margin-left: rem(-24px);
      margin-right: rem(-24px);
    }
  }

  li {
    padding: rem(16px) rem(8px);
    width: 50%;

    @include mappy-bp(sm) {
      padding: rem(12px);
      width: auto;
    }

    @include mappy-bp(lg) {
      padding-left: rem(24px);
      padding-right: rem(24px);
    }
  }

  a {
    display: inline-block;
    color: $color-dark-blue;
    font-weight: 700;
    transition: all $transition-time-default;

    &:hover,
    &:focus {
      color: $color-primary;
    }
  }

  a.active {
    color: $color-primary;
  }
}

// Sentinels
.c-anchornav-sentinel {
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  // background-color: red; // for debugging purposes
}

.c-anchornav-sentinel--top {
  top: -2px;
}

.c-anchornav-sentinel--bottom {
  bottom: -2px;
}
