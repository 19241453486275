//
// Fonts
// --------------------------------------------------

/* stylelint-disable value-list-comma-newline-after */

@font-face {
  font-family: "Between_3 W01";
  font-weight: 300;
  src: url("../fonts/between/d3433f85-ac6a-4c94-805b-5cf50390320e.eot?#iefix");
  src: url("../fonts/between/d3433f85-ac6a-4c94-805b-5cf50390320e.eot?#iefix") format("eot"), url("../fonts/between/eac74d1c-70ba-4085-9244-53b0a0b1f609.woff2") format("woff2"), url("../fonts/between/4874312d-825f-42b9-831f-1c852268ade0.woff") format("woff"), url("../fonts/between/c3e6e80a-39ba-477a-b972-fdc68b5730fd.ttf") format("truetype");
}

@font-face {
  font-family: "Between_3 W01";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/between/bb87af75-e769-4118-afd2-3ca8af761986.eot?#iefix");
  src: url("../fonts/between/bb87af75-e769-4118-afd2-3ca8af761986.eot?#iefix") format("eot"), url("../fonts/between/32b02271-e869-476f-890d-48c004bb66e2.woff2") format("woff2"), url("../fonts/between/3c30294a-d967-4fff-95cc-cba7e340069a.woff") format("woff"), url("../fonts/between/2c693a95-bb5b-43ce-996e-ef9bcb987b44.ttf") format("truetype");
}

@font-face {
  font-family: "Between_3 W01";
  font-weight: 700;
  src: url("../fonts/between/26159528-3ff6-45ed-af10-a3138b2ca084.eot?#iefix");
  src: url("../fonts/between/26159528-3ff6-45ed-af10-a3138b2ca084.eot?#iefix") format("eot"), url("../fonts/between/6381d432-12d6-4151-9cb6-70a97708efa6.woff2") format("woff2"), url("../fonts/between/a9ff2673-70c8-4509-8870-6a80a5278e1f.woff") format("woff"), url("../fonts/between/421bed3c-f8c5-4862-b478-7ffb0894114f.ttf") format("truetype");
}

/* stylelint-enable */
