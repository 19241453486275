//
// Topbar
// --------------------------------------------------

:root {
  --topbar-height: #{$estimated-topbar-height}; // should be adjusted by JS
}

@keyframes topbarNodeReady {
  from { clip: rect(1px, auto, auto, auto); }
  to { clip: rect(0, auto, auto, auto); }
}

.c-topbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: index($z-index, topbar);
  background-image: $gradient-primary;

  // https://keithclark.co.uk/articles/working-with-elements-before-the-dom-is-ready/
  animation-name: topbarNodeReady;
  animation-duration: .001s;

  // fixed topbar height
  height: rem($topbar-height);

  @include mappy-bp(sm) {
    height: rem($topbar-height-sm);
  }

  @include mappy-bp(lg) {
    height: rem($topbar-height-lg);
  }
}

.c-topbar__container,
.c-topbar__inner,
.c-topbar__right {
  height: 100%;
}

.c-topbar__inner {
  display: flex;
  justify-content: space-between;

  .c-topbar:not(.c-topbar--family) & {
    @include page-offset;
  }

  .c-topbar--family & {
    justify-content: center;
    align-items: center;
  }
}

.c-topbar__left {
  flex-shrink: 0;
  height: 100%;
}

.c-topbar__right {
  flex-shrink: 0;
  margin-left: rem(24px);
  padding-top: rem(24px);

  @include mappy-bp(sm) {
    padding-top: rem(48px);
  }
}

.c-topbar__menu {
  @include mappy-bp(max xl) {
    display: none;
  }
}

.c-topbar__actions {
  @include mappy-bp(xl) {
    display: none;
  }
}

// Button
.c-topbar__btn {
  @include button-reset;
  cursor: pointer;
  transition: all $transition-time-default;
  display: block;

  svg {
    height: rem($topbar-btn-size);
    width: rem($topbar-btn-size);

    @include mappy-bp(sm) {
      height: rem($topbar-btn-size-sm);
      width: rem($topbar-btn-size-sm);
    }
  }
}

.c-topbar__btn--menu-open {
  &,
  &:hover,
  &:focus {
    color: $color-primary;
  }
}

.c-topbar__btn--menu-open-cyan {
  &,
  &:hover,
  &:focus {
    color: $color-cyan;
  }
}

// Site switch
.c-topbar__site-switch {
  background-image: url('../../img/logo-bg.png');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  min-height: 100%;
  padding-bottom: rem(24px);
  position: relative;
  padding-left: rem($topbar-btn-size + 8px);
  width: rem(187px);

  @include mappy-bp(sm) {
    padding-left: rem($topbar-btn-size-sm + 16px);
    width: rem(320px);
    padding-bottom: rem(32px);
  }

  @include mappy-bp(lg) {
    padding-bottom: rem(48px);
    padding-left: rem($topbar-btn-size-sm + 32px);
    width: rem(400px);
  }
}

.c-topbar__site-switch-logos {
  display: flex;
  flex-direction: column;
  width: rem(126px);

  @include mappy-bp(sm) {
    width: rem(214px);
    padding-top: rem(10px);
  }

  @include mappy-bp(lg) {
    width: rem(268px);
  }
}

.c-topbar__logo {
  display: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.c-topbar__site-switch.is-open .c-topbar__logo,
.c-topbar__logo--current {
  display: block;
}

// always show current logo first
.c-topbar__logo--current {
  order: -10;
}


.c-topbar__site-switch-toggle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10; // > logos
  padding-top: rem(24px);

  @include mappy-bp(sm) {
    padding-top: rem(48px);
  }
}

.c-topbar__btn--site-switch {
  display: inline-block;
  color: $color-primary;

  &:hover,
  &:focus {
    color: $color-dark-blue;
  }

  .c-topbar__site-switch.is-open & {
    svg {
      transform: rotate(180deg);
    }
  }
}

.c-topbar__btn--site-switch-cyan {
  color: $color-cyan;
}


.c-topbar__family-logo {
  display: block;
  height: 78%;

  img {
    display: block;
    height: 100%;
    width: auto;
  }
}
