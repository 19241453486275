//
// Hero animation
// --------------------------------------------------

$hero-animation-base-height: 762px;
$hero-animation-base-width: 1658px;

.c-hero-animation {
  display: flex;
  justify-content: center;

  .c-hero + & {
    margin-top: rem(-27px);

    @include mappy-bp(sm) {
      margin-top: rem(-56px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(-88px);
    }

    @include mappy-bp(xl) {
      margin-top: rem(-300px);
    }
  }
}

.c-hero-animation__inner {
  width: 100%;
  min-width: rem(545px);
  max-width: rem(1658px);
  margin-left: rem($container-padding);
  margin-right: rem($container-padding);

  @include mappy-bp(sm) {
    min-width: rem(1169px);
  }

  @include mappy-bp(sm md) {
    // exception: downscale for cyan
    .c-hero-animation--cyan & {
      transform: scale(.85);
    }
  }
}

.c-hero-animation__ar-parent {
  padding-bottom: $hero-animation-base-height / $hero-animation-base-width * 100%;
  position: relative;
  height: 0;
}

.c-hero-animation__ar-target {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

// BG
.c-hero-animation__bg-wrapper {
  overflow: hidden;
}

.c-hero-animation__bg-img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: opacity $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}


// Items
.c-hero-animation__item {
  position: absolute !important; // the script sets first child to relative for some reason
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.c-hero-animation__graphic {
  display: block;
  position: absolute;

  // Default items
  .c-hero-animation__item--scene & {
    top: 211px / $hero-animation-base-height * 100%;
    left: 210px / $hero-animation-base-width * 100%;
    width: 1284px / $hero-animation-base-width * 100%;
    height: 309px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--house & {
    top: 215px / $hero-animation-base-height * 100%;
    left: 1018px / $hero-animation-base-width * 100%;
    width: 195px / $hero-animation-base-width * 100%;
    height: 282px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--relax & {
    top: 510px / $hero-animation-base-height * 100%;
    left: 487px / $hero-animation-base-width * 100%;
    width: 220px / $hero-animation-base-width * 100%;
    height: 71px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--catch & {
    top: 478px / $hero-animation-base-height * 100%;
    left: 820px / $hero-animation-base-width * 100%;
    width: 235px / $hero-animation-base-width * 100%;
    height: 155px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--butterfly & {
    top: -50px / $hero-animation-base-height * 100%;
    left: 335px / $hero-animation-base-width * 100%;
    width: 951px / $hero-animation-base-width * 100%;
    height: 473px / $hero-animation-base-height * 100%;

    @include mappy-bp(xl) {
      top: 126px / $hero-animation-base-height * 100%;
      left: -49px / $hero-animation-base-width * 100%;
    }
  }

  // Cyan items
  .c-hero-animation__item--scene-haende & {
    top: 190px / $hero-animation-base-height * 100%;
    left: 211px / $hero-animation-base-width * 100%;
    width: 1282px / $hero-animation-base-width * 100%;
    height: 325px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--slide & {
    top: 318px / $hero-animation-base-height * 100%;
    left: 791px / $hero-animation-base-width * 100%;
    width: 381px / $hero-animation-base-width * 100%;
    height: 202px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--volley1 & {
    top: 353px / $hero-animation-base-height * 100%;
    left: 515px / $hero-animation-base-width * 100%;
    width: 161px / $hero-animation-base-width * 100%;
    height: 210px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--volley2 & {
    top: 468px / $hero-animation-base-height * 100%;
    left: 704px / $hero-animation-base-width * 100%;
    width: 111px / $hero-animation-base-width * 100%;
    height: 141px / $hero-animation-base-height * 100%;
  }

  .c-hero-animation__item--bird & {
    top: -321px / $hero-animation-base-height * 100%;
    left: 23px / $hero-animation-base-width * 100%;
    width: 1030px / $hero-animation-base-width * 100%;
    height: (481 / 755) * 1030px / $hero-animation-base-height * 100%;

    @include mappy-bp(xl) {
      top: 29px / $hero-animation-base-height * 100%;
      left: -67px / $hero-animation-base-width * 100%;
    }
  }
}
