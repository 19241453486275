//
// Menu
// --------------------------------------------------

$menu-mobile-collapse-duration: $transition-time-default;
$menu-submenu-shadow: 0 0 rem(8px) rgba(0, 0, 0, .25);
$menu-item-padding-x: 24px;
$menu-item-indicator-size: 12px;
$menu-item-min-height: $menu-item-indicator-size;

.c-menu {
  color: $color-primary;
}

.c-menu--cyan {
  color: $color-cyan;
}

// Level 1
// List
.c-menu-level1 {
  @include mappy-bp(xl) {
    display: flex;
  }
}

// Item
.c-menu-level1__item {
  position: relative; // for submenu

  @include mappy-bp(xl) {
    display: flex;
    align-items: center;
  }
}

// Link wrappers
.c-menu-level1__link-wrapper {
  position: relative;
  z-index: 10; // > submenu
}

// Link
.c-menu-level1__link {
  position: relative; // for active state indicator
  display: inline-flex; // might contain submenu indicator
  align-items: center;

  color: inherit;
  transition: color $transition-time-default;
  padding: rem(12px) rem($menu-item-padding-x);

  .c-menu-level1__item.is-open &,
  &:hover,
  &:focus {
    color: $color-dark-blue;
  }

  .c-menu-level1__item.is-active & {
    color: #fff;
    background-color: $color-primary;

    @supports (clip-path: polygon(0 0)) {
      clip-path: polygon(100% 0, 100% $button-diagonal-offset, 0 100%, 0 0);
    }

    &:hover,
    &:focus {
      color: $color-dark-blue;
    }

    .c-menu--cyan & {
      background-color: $color-cyan;
    }
  }
}

// Title
.c-menu-level1__title {
  position: relative;
  z-index: 10; // > bg

  @include typi('menu-level1');
  font-weight: 700;
  margin-right: auto;
  //min-height: rem($menu-item-min-height); // for when no indicator is present

  @supports (clip-path: polygon(0 0)) {
    transform: translateY(rem(-2px));
  }
}

// Indicator
.c-menu-level1__indicator {
  flex-shrink: 0;
  margin-left: rem(8px);

  @include mappy-bp(xl) {
    color: $color-cyan;
  }

  svg {
    width: rem($menu-item-indicator-size);
    height: rem($menu-item-indicator-size);
    //transition: all $menu-mobile-collapse-duration;

    .c-menu-level1__item.is-open & {
      transform: rotate(180deg);
    }
  }
}

// Level 2
// List
.c-menu-level2 {
  display: none;

  margin-top: rem(8px);
  padding: rem(16px);
  background-color: rgba($color-light-blue, .2);

  @include mappy-bp(xl) {
    margin-top: rem(16px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    background-color: $color-gray-f;

    position: absolute;
    left: 0;
    top: 100%;
    min-width: rem(264px);
    box-shadow: $menu-submenu-shadow;

    .c-menu-level1__item:last-of-type() & {
      right: 0;
      left: auto;
    }
  }

  .c-menu-level1__item.is-open & {
    display: block;
  }
}

// Item
.c-menu-level2__item {
  position: relative; // for submenu
}

// Link wrappers
.c-menu-level2__link-wrapper {
  position: relative;
  z-index: 10; // > submenu
}

// Link
.c-menu-level2__link {
  display: flex; // might contain indicator
  align-items: center;

  color: #fff;
  padding: rem(8px) rem($menu-item-padding-x) rem(16px);

  @include mappy-bp(xl) {
    color: $color-green;
  }

  .c-menu-level2__item.is-open &,
  &:hover,
  &:focus {
    color: $color-dark-blue;
    background-color: rgba($color-gray-f, .4);

    @include mappy-bp(xl) {
      color: $color-green;
      background-color: #fff;
    }
  }

  .c-menu-level2__item.is-active & {
    @include mappy-bp(xl) {
      color: $color-dark-blue;
    }
  }
}

// Indicator: no indicator for level 2 & 3
// ...

// Title
.c-menu-level2__title {
  @include typi('menu-level2');
  font-weight: 700;
  margin-right: auto;
}
