//
// Article actions
// --------------------------------------------------

.c-article-actions__inner {
  @include page-offset;

  > .o-row {
    align-items: flex-end;
  }
}
