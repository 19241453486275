//
// Infobox
// --------------------------------------------------

$infobox-icon-size: 44px;
$infobox-icon-size-sm: 62px;
$infobox-icon-size-lg: 80px;


.c-infobox {
  max-width: rem($content-width-narrow);

  padding: rem($container-padding);
  padding-left: rem($container-padding / 2);
  background-color: $color-yellow;
  color: #fff;

  display: flex;
  align-items: center;

  @include mappy-bp(sm) {
    padding: rem($container-padding) rem($container-padding-sm);
  }

  @include mappy-bp(lg) {
    padding: rem($container-padding-sm);
  }
}

@each $color, $hex in (
  "orange": $color-orange,
  "cyan": $color-cyan,
  "yellow": $color-yellow,
  "green": $color-green,
  "gray": $color-gray-a,
) {
  .c-infobox--#{$color} {
    background-color: $hex;
  }
}

.c-infobox__icon {
  align-self: flex-start;
  flex-shrink: 0;
  height: rem($infobox-icon-size);
  width: rem($infobox-icon-size);
  margin-right: rem(8px);

  @include mappy-bp(sm) {
    height: rem($infobox-icon-size-sm);
    width: rem($infobox-icon-size-sm);
    margin-right: rem(24px);
  }

  @include mappy-bp(lg) {
    height: rem($infobox-icon-size-lg);
    width: rem($infobox-icon-size-lg);
    margin-right: rem(32px);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.c-infobox__content {
  flex-grow: 1;
}

.c-infobox__link {
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(32px);
  }
}
