//
// Alertbar
// --------------------------------------------------

$alertbar-icon-ratio: 62 / 68;

.c-alertbar {
  display: block;
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  background-color: $color-success;
  color: #fff;

  margin-bottom: rem(10px); // kinda bad

  @include mappy-bp(sm) {
    padding-top: rem(40px);
    padding-bottom: rem(40px);

    margin-bottom: 0;
  }

  @include mappy-bp(lg) {
    padding-top: rem(48px);
    padding-bottom: rem(48px);
  }
}

.c-alertbar__container,
.c-alertbar__inner {
  height: 100%;
}

.c-alertbar__inner {
  display: flex;
}

.c-alertbar__icon {
  flex-shrink: 0;
  width: rem(39px);
  height: rem(39px * $alertbar-icon-ratio);

  @include mappy-bp(sm) {
    width: rem(50px);
    height: rem(50px * $alertbar-icon-ratio);
  }

  @include mappy-bp(lg) {
    width: rem(71px);
    height: rem(71px * $alertbar-icon-ratio);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.c-alertbar__text {
  flex-grow: 1;
  margin-left: rem(16px);

  @include mappy-bp(sm) {
    margin-left: rem(24px);
  }

  @include mappy-bp(lg) {
    margin-left: rem(40px);
  }
}
