//
// Jobs overview
// --------------------------------------------------

.c-jobs-overview__job {
  &::after {
    content: '';
    display: block;
    @include make-separator-svg;
    @include page-offset;

    // = .c-richtext hr
    margin-top: rem(16px);
    margin-bottom: rem(32px);

    @include mappy-bp(sm) {
      margin-top: rem(32px);
      margin-bottom: rem(72px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(40px);
      margin-bottom: rem(96px);
    }
  }

  &:last-child::after {
    margin-bottom: 0;
  }
}
