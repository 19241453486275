//
// Toplink
// --------------------------------------------------

.c-toplink {
  position: fixed;
  z-index: index($z-index, toplink);
  right: rem($container-padding);
  bottom: rem($container-padding);
  color: $color-primary;

  @include mappy-bp(md) {
    right: rem(40px);
    bottom: rem(32px);
  }
}

.c-toplink--cyan {
  color: $color-cyan;
}


// Button
.c-toplink__btn {
  transition: all $transition-time-default;
  display: block;
  color: inherit;

  &:focus,
  &.is-scrolling {
    color: $color-dark-blue;
  }

  @media (hover) {
    &:hover {
      color: $color-dark-blue;
    }
  }

  svg {
    transform: rotate(180deg);
    height: rem($toplink-btn-size);
    width: rem($toplink-btn-size);

    @include mappy-bp(sm) {
      height: rem($toplink-btn-size-sm);
      width: rem($toplink-btn-size-sm);
    }
  }
}
