//
// Footer
// --------------------------------------------------

.c-footer {
  width: 100%;
  position: relative;
  z-index: index($z-index, footer);

  background-color: $color-green;
  color: #fff;
  @include typi('footer');

  // grass visual
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(100% - 1px);
    z-index: 10; // > ball

    background-image: url("data:image/svg+xml,%3Csvg width='387' height='64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 21.677l23 26.84v-13.42h13l8 13.42 10-26.84h10l9 26.84v-13.42h10.557l.745 13.42L109 21.676v26.84L137.469 0v48.516l13.283-13.42 5.375 13.42L178.515 0v54.71h14.342l8.053-33.033 9.09 5.678 6 27.355 11-6.194 6.771-26.839L244 48.517 251 0l8 48.516 11.278-26.839 8.412 26.84 17.655-21.162L314 35.097V54.71l13.239-6.194V0L342 48.516l9-34.064h10v34.064L387 0v64H0z' fill='%238ACB48' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-size: contain;
    background-position: center center;

    height: rem(26px);

    @include mappy-bp(sm) {
      height: rem(51px);
    }

    @include mappy-bp(md) {
      height: rem(64px);
    }
  }
}

.c-footer__inner {
  position: relative;
  padding-top: rem(24px);
  padding-bottom: rem(40px);

  // make room for toplink
  padding-right: rem($toplink-btn-size);

  @include mappy-bp(sm) {
    padding-right: rem($toplink-btn-size-sm);
  }

  @include mappy-bp(md) {
    padding-top: rem(40px);

    display: flex;
    justify-content: space-between;
  }
}

.c-footer__alpha {
  flex-shrink: 0;
}

.c-footer__beta {
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(24px);
  }

  @include mappy-bp(md) {
    margin-top: 0;
    margin-left: rem(32px);
    text-align: right;
  }
}

// Nav
.c-footer__nav {
  ul {
    margin: rem(-4px) rem(-16px);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  li {
    margin: rem(4px) rem(16px);

    a {
      color: inherit;
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
      }

      svg {
        width: 1em;
        height: 1em;
        margin-right: .5em;
      }
    }
  }

  li.is-active a {
    font-weight: 700;
  }
}

// Ball
.c-footer__ball {
  position: absolute;
  right: 24%;
  bottom: 100%;

  display: block;
  width: rem(40px);
  height: rem(40px);

  @include mappy-bp(xs) {
    width: rem(48px);
    height: rem(48px);
  }

  @include mappy-bp(sm) {
    width: rem(96px);
    height: rem(96px);
  }

  @include mappy-bp(lg) {
    width: rem(122px);
    height: rem(122px);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
