//
// Link button
// --------------------------------------------------

$link-button-icon-size: 46px;
$link-button-icon-size-sm: 81px;
$link-button-icon-ratio: 44 / 81;
$link-button-hover-transform-amount: 30%;

.c-link-button {
  display: inline-flex;
  align-items: center;

  // if the component is an anchor, provide correct coloring
  @at-root a#{&} {
    transition: color $transition-time-default;
    color: $color-link;

    &:hover,
    &:focus {
      color: $color-dark-blue;

      svg {
        transform: translateX($link-button-hover-transform-amount);
      }
    }
  }

  span {
    @include typi('link-button');
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
  }

  span + svg {
    margin-left: rem(8px);
  }

  svg {
    width: rem($link-button-icon-size);
    height: rem($link-button-icon-size * $link-button-icon-ratio);
    transition: transform $transition-time-medium;
    will-change: transform;

    @include mappy-bp(sm) {
      width: rem($link-button-icon-size-sm);
      height: rem($link-button-icon-size-sm * $link-button-icon-ratio);
    }

    .c-teaser-alpha:hover &,
    .c-teaser-alpha:focus &,
    .glide__arrow:hover &,
    .glide__arrow:focus & {
      transform: translateX($link-button-hover-transform-amount);
    }
  }
}

.c-link-button--disabled {
  opacity: .5;
}

.c-link-button--inverted {
  @at-root a#{&} {
    &,
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.c-link-button--reverse {
  flex-direction: row-reverse;

  span + svg {
    margin-left: 0;
    margin-right: rem(8px);
  }

  svg {
    transform: rotate(180deg);

    .c-teaser-alpha:hover &,
    .c-teaser-alpha:focus &,
    .glide__arrow:hover &,
    .glide__arrow:focus & {
      transform: rotate(180deg) translateX($link-button-hover-transform-amount);
    }
  }
}
