//
// Section wrapper
// --------------------------------------------------

.c-section-wrapper__icon-wrapper {
  position: relative;
}

.c-section-wrapper__icon {
  width: rem(47px);
  height: rem(47px);
  margin-bottom: rem(8px);

  @include mappy-bp(sm) {
    width: rem(88px);
    height: rem(88px);
  }

  @include mappy-bp(lg) {
    width: rem(108px);
    height: rem(108px);
  }

  @include mappy-bp(1676px) {
    position: absolute;
    z-index: 10;
    top: 0;
    right: calc(100% + #{rem(56px)});
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
