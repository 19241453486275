//
// Card
// --------------------------------------------------

.c-card {
  display: flex;

  @include mappy-bp(sm) {
    display: block;
  }
}

.c-card--stacked {
  display: block;

  @include mappy-bp(sm) {
    display: flex;
  }
}

// Image
.c-card__img-block {
  flex-shrink: 0;
  width: rem(88px);
  max-width: rem(320px);

  @include mappy-bp(sm) {
    width: rem(224px);
  }

  @include mappy-bp(md) {
    width: auto;
  }

  .c-card--stacked & {
    margin-left: auto;
    margin-right: auto;
    width: rem(136px);

    @include mappy-bp(sm) {
      margin-left: 0;
      margin-right: 0;
      width: rem(176px);
    }

    @include mappy-bp(md) {
      width: rem(288px);
    }
  }
}

.c-card__img-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: (288 / 400) * 100%;
  height: 0;

  background-image: url('../../img/brush-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  .c-card--stacked & {
    padding-bottom: (254 / 288) * 100%;
  }
}

.c-card__img {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: top left;
  transition: opacity $transition-time-default;

  @include mappy-bp(md) {
    object-position: bottom left;
  }

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-card__content {
  .c-card__img-block + & {
    margin-left: rem(8px);

    @include mappy-bp(sm) {
      margin-left: 0;
      margin-top: rem(32px);
    }

    @include mappy-bp(md) {
      margin-top: rem(64px);
    }

    .c-card--stacked & {
      margin-left: 0;
      margin-top: rem(24px);

      @include mappy-bp(sm) {
        margin-top: 0;
        margin-left: rem(48px);
      }
    }
  }
}


// Wrapper component
.c-cards--stacked {
  max-width: rem($content-width-narrow);
}

.c-cards__text {
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(72px);
  }
}

// Grid
$cards-spacing: 32px;
$cards-spacing-sm: 48px;
$cards-vertical-spacing: 40px;
$cards-vertical-spacing-sm: 72px;

.c-cards__row {
  @include make-row-spacing($cards-spacing, $cards-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-row-spacing($cards-spacing-sm, $cards-vertical-spacing-sm);
  }
}

.c-cards__col {
  @include make-col-spacing($cards-spacing, $cards-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-col-spacing($cards-spacing-sm, $cards-vertical-spacing-sm);
  }
}

