//
// Share links
// --------------------------------------------------

$sharelinks-icon-size: 30px;
$sharelinks-icon-size-lg: 40px;

.c-sharelinks__buttons {
  display: flex;
}

.c-sharelinks__button {
  position: relative;
  display: block;
  flex-shrink: 0;
  @include button-reset;
  cursor: pointer;
  transition: all $transition-time-default;
  height: rem($sharelinks-icon-size);
  width: rem($sharelinks-icon-size);

  border-radius: rem(4px);
  overflow: hidden;

  @include mappy-bp(lg) {
    height: rem($sharelinks-icon-size-lg);
    width: rem($sharelinks-icon-size-lg);
  }

  &:hover,
  &:focus {
    opacity: .8;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  & + & {
    margin-left: rem(16px);

    @include mappy-bp(lg) {
      margin-left: rem(24px);
    }
  }
}

.c-sharelinks__button--whatsapp {
  @include mappy-bp(md) {
    display: none;
  }
}
