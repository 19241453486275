//
// Footer CTA
// --------------------------------------------------

.c-footer-cta {
  position: relative;
  z-index: index($z-index, footer-cta);

  background-image: url('../../img/brush-pattern-bg.png');
  background-repeat: repeat-x;
  background-position: top center;

  padding-top: rem($section-spacing);
  padding-bottom: rem($section-spacing * 3);

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm);
    padding-bottom: rem($section-spacing-sm * 2);
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg);
    padding-bottom: rem($section-spacing-lg * 2);
  }
}

// Content
.c-footer-cta__content {
  position: relative;
  z-index: 10;

  text-align: center;

  h2 {
    color: $color-primary;

    .c-footer-cta--cyan & {
      color: $color-cyan;
    }
  }
}

.c-footer-cta__actions {
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(40px);
  }
}

.c-footer-cta__animation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}


// Animation
$footer-animation-base-height: 580px;
$footer-animation-base-width: 1296px;

.c-footer-animation {
  display: flex;
  justify-content: center;
}

.c-footer-animation__inner {
  width: 100%;
  min-width: rem(320px);
  max-width: rem(1296px);
  margin-left: rem($container-padding);
  margin-right: rem($container-padding);

  @include mappy-bp(sm) {
    min-width: rem(624px);
  }
}

.c-footer-animation__ar-parent {
  padding-bottom: $footer-animation-base-height / $footer-animation-base-width * 100%;
  position: relative;
  height: 0;
}

.c-footer-animation__ar-target {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.c-footer-animation__item {
  position: absolute !important; // the script sets first child to relative for some reason
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.c-footer-animation__graphic {
  display: block;
  position: absolute;

  .c-footer-animation__item--ball & {
    bottom: 0;
    left: 870px / $footer-animation-base-width * 100%;
    width: 234px / $footer-animation-base-width * 100%;
    height: 234px / $footer-animation-base-height * 100%;

    @include mappy-bp(xs) {
      width: 169px / $footer-animation-base-width * 100%;
      height: 169px / $footer-animation-base-height * 100%;
    }

    @include mappy-bp(sm) {
      width: 140px / $footer-animation-base-width * 100%;
      height: 140px / $footer-animation-base-height * 100%;
    }

    @include mappy-bp(lg) {
      width: 122px / $footer-animation-base-width * 100%;
      height: 122px / $footer-animation-base-height * 100%;
    }

    .c-footer-animation--cyan & {
      left: auto;
      right: 870px / $footer-animation-base-width * 100%;
    }
  }

  .c-footer-animation__item--butterfly & {
    top: 289px / $footer-animation-base-height * 100%;
    left: 152px / $footer-animation-base-width * 100%;
    width: 226px / $footer-animation-base-width * 100%;
    height: 163px / $footer-animation-base-height * 100%;

    @include mappy-bp(sm) {
      top: 169px / $footer-animation-base-height * 100%;
      left: 100px / $footer-animation-base-width * 100%;
      width: 117px / $footer-animation-base-width * 100%;
      height: 84px / $footer-animation-base-height * 100%;
    }
  }

  .c-footer-animation__item--bird & {
    top: 289px / $footer-animation-base-height * 100%;
    right: 152px / $footer-animation-base-width * 100%;
    width: 226px / $footer-animation-base-width * 100%;
    height: 163px / $footer-animation-base-height * 100%;

    @include mappy-bp(sm) {
      top: 169px / $footer-animation-base-height * 100%;
      right: 100px / $footer-animation-base-width * 100%;
      width: 117px / $footer-animation-base-width * 100%;
      height: 84px / $footer-animation-base-height * 100%;
    }
  }
}
