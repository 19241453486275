//
// Text
// --------------------------------------------------

.c-text {
  max-width: rem($content-width-narrow);
}

.c-text--wide {
  max-width: none;
}

.c-text--centered {
  margin-left: auto;
  margin-right: auto;
}
