//
// Site teaser
// --------------------------------------------------

.c-teaser-site {
  position: relative;
  display: block;

  background-color: #fff;
  border: rem(4px) solid $color-primary;
  border-radius: rem(32px);
  overflow: hidden;

  @include mappy-bp(max xs) {
    border-width: rem(3px);
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}

.c-teaser-site--cyan {
  border-color: $color-cyan;
}

.c-teaser-site__inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  bottom: 10%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.c-teaser-site__logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateY(-5%);

    @include mappy-bp(max xs) {
      transform: translateY(10%);
    }
  }
}

.c-teaser-site__headline,
.c-teaser-site__cta {
  position: relative;
  z-index: 10; // > logo
}

.c-teaser-site__headline {
  @include mappy-bp(max xs) {
    transform-origin: bottom center;
    transform: scale(.75);
  }
}

.c-teaser-site__cta {
  @include mappy-bp(max xs) {
    display: none;
  }
}


// Grid
$teasers-site-spacing: 12px;
$teasers-site-spacing-xs: 24px;
$teasers-site-spacing-sm: 56px;
$teasers-site-vertical-spacing: 40px;
$teasers-site-vertical-spacing-xs: 40px;
$teasers-site-vertical-spacing-sm: 72px;

.c-site-teasers__row {
  @include make-row-spacing($teasers-site-spacing, $teasers-site-vertical-spacing);

  @include mappy-bp(xs) {
    @include make-row-spacing($teasers-site-spacing-xs, $teasers-site-vertical-spacing-xs);
  }

  @include mappy-bp(sm) {
    @include make-row-spacing($teasers-site-spacing-sm, $teasers-site-vertical-spacing-sm);
  }
}

.c-site-teasers__col {
  @include make-col-spacing($teasers-site-spacing, $teasers-site-vertical-spacing);

  @include mappy-bp(xs) {
    @include make-col-spacing($teasers-site-spacing-xs, $teasers-site-vertical-spacing-xs);
  }

  @include mappy-bp(sm) {
    @include make-col-spacing($teasers-site-spacing-sm, $teasers-site-vertical-spacing-sm);
  }
}

