//
// Locations
// --------------------------------------------------

.c-locations__text {
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }

  @include mappy-bp(md) {
    padding-bottom: rem(72px);
  }
}

.c-locations__map {
  @include page-offset($right: true);
}

.c-locations__link {
  display: flex;
  justify-content: center;

  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: rem(32px);
  }

  @include mappy-bp(md) {
    margin-top: rem(56px);
  }
}
